import axiosCommon from 'utils/axios/axiosCommon';
import axiosAuth from 'utils/axios/axiosAuth';

const authApi = {
  checkAuth: () => {
    return axiosCommon.post(`user/authentication/erp`, {}, { withCredentials: true });
  },
  signOut: () => {
    return axiosCommon.post(`user/logout`, {}, { withCredentials: true });
  },
  getAllowedActions: ({ component }) => {
    return axiosAuth.get(`user/get_allowed_actions`, {
      params: { component },
    });
  },
};

export default authApi;
