import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import UserDropdown from './UserDropdown/UserDropdown';
import Button from '@material-ui/core/Button';
import ServicesPanel from 'components/PageHeader/ServicesPanel/ServicesPanel';

import classes from './PageHeader.module.scss';

class PageHeader extends PureComponent {
  getNavLinkClass = (pathArr) => {
    return pathArr.some((path) => this.props.location.pathname.split('/').includes(path.replace('/', '')))
      ? classes.Active
      : '';
  };
  handleGoToOrder = () => {
    window.location.href = `${process.env.REACT_APP_PATH_ECOM}`;
  }

  render() {
    return (
      <div className={classes.Header}>
        <Link to="/" className={classes.Brand}>
          <img className={classes.Logo} src={`${process.env.REACT_APP_PATH}static/logo/logo.png`} alt="Getcare" />
        </Link>
        <ul className={classes.RightNav}>
          <li>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              className={classes.OrderBtn}
              onClick={this.handleGoToOrder}
            >
              Đặt hàng nhanh
            </Button>
          </li>
          <li>
            <ServicesPanel iconSize="default" />
          </li>
          <li>
            <UserDropdown />
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(PageHeader);
