export const MAX_FILE_SIZE = 5*1024*1024;

export const listPageSizes = [50, 100, 150];
export const suggestionPageSize = 200;

export const DEFAULT_PAGE = `/`;

export const ACTIVE = 1;
export const DEACTIVE = -1;
export const activeStatusMap = {
  [ACTIVE]: 'Đang hoạt động',
  [DEACTIVE]: 'Ngừng hoạt động',
};

export const LOGISTIC_GETCARE_ID = 1;
export const LOGISTIC_CHANH_XE_ID = 2;
