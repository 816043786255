import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { defaultTheme } from 'assets/js/themes';

import LayoutAdmin from 'layouts/LayoutAdmin/LayoutAdmin';
import Dashboard from 'views/erp/Dashboard/Dashboard';

import './App.scss';

const theme = createMuiTheme(defaultTheme);

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <Switch>
            <Route path='/'>
              <LayoutAdmin>
                <Switch>
                  <Route path='/' component={Dashboard} />
                </Switch>
              </LayoutAdmin>
            </Route>
            <Route path='*'>
              <LayoutAdmin>
                <Switch>
                  <Route path='*' component={Dashboard} />
                </Switch>
              </LayoutAdmin>
            </Route>
          </Switch>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
