import { takeLatest } from 'redux-saga/effects';

import { CHECK_AUTH, SIGN_OUT } from 'redux/constants/authConstants';
import { checkAuthentication, signMeOut } from 'saga/authSaga';

/**
 * Root saga manages watcher lifecycle
 */
export default function* defaultSaga() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount

  // auth
  yield takeLatest(CHECK_AUTH, checkAuthentication);
  yield takeLatest(SIGN_OUT, signMeOut);
}
