import React, { Component } from 'react';
import PageHeader from 'components/PageHeader/PageHeader';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { checkAuth } from 'redux/actions/authActions';
import { createStructuredSelector } from "reselect";
import { makeSelectLoginUser } from 'redux/selectors';
import { ToastContainer } from 'react-toastify';

import { DEFAULT_PAGE } from 'utils/constanst/common';

import 'react-toastify/dist/ReactToastify.css';
import classes from './LayoutAdmin.module.scss';

class LayoutAdmin extends Component {
  componentDidMount() {
    this.props.checkAuth();
  }
  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (user && user !== prevProps.user && !user.id) {
      // check auth failed
      window.location.href = `${process.env.REACT_APP_PATH_SSO}?continue=${process.env.REACT_APP_PATH_ERP}`;
    }
    if (prevProps.user && prevProps.user.id && (!user || !user.id)) {
      // logout success
      window.location.href = `${process.env.REACT_APP_PATH_SSO}?continue=${process.env.REACT_APP_PATH_ERP}`;
    }
    if (prevProps.user && prevProps.user.id && user && user.id && (prevProps.user.id !== user.id)) {
      // the second user logout success
      window.location.href = DEFAULT_PAGE;
    }
  }

  render() {
    const { children, user } = this.props;
    const hasAuth = user && user.id;

    return (<>
      { (hasAuth) &&
        <div className={classes.Wrap}>
          <PageHeader/>
          { children }
        </div>
      }
      {/* else // todo: redirect to login form */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick
      />
    </>);
  }
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    checkAuth: () => dispatch(checkAuth()),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(LayoutAdmin);
