import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from "reselect";
import { makeSelectLoginUser } from 'redux/selectors';

import PermDataSettingOutlinedIcon from '@material-ui/icons/PermDataSettingOutlined';
import RedeemOutlinedIcon from '@material-ui/icons/RedeemOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';

import classes from './Dashboard.module.scss';

class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.appList = [
      {
        id: 'mdm',
        name: 'Quản lý master data',
        iconStr: 'mdm',
        url: 'mdm.getcare.vn',
        link: `${process.env.REACT_APP_PATH_MDM}`,
      },
      {
        id: 'tmk',
        name: 'Quản lý khuyến mãi',
        iconStr: 'tmk',
        url: 'tmk.getcare.vn',
        link: `${process.env.REACT_APP_PATH_TMK}`,
      },
      {
        id: 'oms',
        name: 'Hệ thống quản lý đơn hàng',
        iconStr: 'oms',
        url: 'oms.getcare.vn',
        link: `${process.env.REACT_APP_PATH_OMS}`,
      },
      {
        id: 'report',
        name: 'Báo cáo',
        iconStr: 'report',
        link: ``,
      },
      {
        id: 'support',
        name: 'Chăm sóc khách hàng',
        iconStr: 'support',
        link: ``,
      },
      {
        id: 'ims',
        name: 'Hệ thống quản lý tồn kho',
        iconStr: 'ims',
        url: 'ims.getcare.vn',
        link: ``,
      },
    ]
  }
  _getUserModuleList = () => {
    const { user } = this.props;
    if (!user || !user.id || !user.getcare_modules) return [];
    return this.appList.map(item => ({
      ...item,
      isDisabled: !user.getcare_modules.includes(item.id),
    }));
  }

  render() {
    const { user } = this.props;

    return (<div className={classes.PageWrap}>
      <div className={classes.Dashboard}>
        <h2 className={classes.Greeting}>Xin chào <strong>{ user?.name }</strong></h2>
        <div className={classes.Modules}>
          { this._getUserModuleList().map(item => (
            <a key={item.id} className={`${classes.ModuleItem} ${item.isDisabled ? classes.Disabled : ''}`} href={item.link || '#'}>
              <div className={classes.ModuleItemInner}>
                { item.iconStr === 'mdm' && <PermDataSettingOutlinedIcon className={classes.Icon}/> }
                { item.iconStr === 'tmk' && <RedeemOutlinedIcon className={classes.Icon}/> }
                { item.iconStr === 'oms' && <ShoppingCartOutlinedIcon className={classes.Icon}/> }
                { item.iconStr === 'report' && <TrendingUpOutlinedIcon className={classes.Icon}/> }
                { item.iconStr === 'support' && <img alt="" className={classes.Icon} src={`${process.env.REACT_APP_PATH}static/icons/support-24px.svg`} /> }
                { item.iconStr === 'ims' && <img alt="" className={classes.Icon} src={`${process.env.REACT_APP_PATH}static/icons/inventory_2-24px.svg`} /> }
                <p>{ item.name }</p>
                <span>{ item.url || '' }</span>
              </div>
            </a>)
          ) }
        </div>
      </div>
    </div>);
  }
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(Dashboard);

