export const DF_FS = 16;
export const REM = '1rem';

export const defaultTheme = {
  palette: {
    primary: {
      light: '#6B8199',
      main: '#6B8199',
      dark: '#475666',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#FFE6CC',
      main: '#ED1C24',
      dark: '#CC0000',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    fontSize: 14,
    button: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontWeight: 600,
      letterSpacing: 0,
      textTransform: 'none',
      fontSize: 14,
    },
    body1: {
      letterSpacing: 0,
      fontSize: 14,
    },
    body2: {
      letterSpacing: 0,
      fontSize: 14,
    }
  },
};
