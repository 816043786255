import { createSelector } from 'reselect';

// authentication
const selectAuthState = (state) => {
  return state.authReducer || {};
};
const makeSelectLoginUser = () =>
  createSelector(selectAuthState, (substate) => substate.user);
const makeSelectAuthLoading = () =>
  createSelector(selectAuthState, (substate) => substate.authLoading);
const makeSelectAuthError = () =>
  createSelector(selectAuthState, (substate) => substate.authError);

// websocket
const selectWSState = (state) => {
  return state.wsReducer || {};
};
const makeSelectGlobalWS= () =>
  createSelector(
    selectWSState,
    (substate) => substate.ws
  );
const makeSelectWSEvents= () =>
  createSelector(
    selectWSState,
    (substate) => substate.events
  );

export {
  makeSelectLoginUser,
  makeSelectAuthLoading,
  makeSelectAuthError,
  makeSelectGlobalWS,
  makeSelectWSEvents,
};
